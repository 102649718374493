// 采集文章
export const sourceList = {
    1: "36氪",
    2: "创业邦",
    3: "雨果网",
    4: "腾讯科技",
    5: "钛媒体",
    6: "techinasia",
};

// 商品类型
export const productType = {
    0: "实体商品",
    1: "虚拟商品",
    2: "优惠券",
    3: "体验券",
};

// 报告支付方式
export const reportPaymentType = {
    3: "积分查看",
    4: "帆币查看",
    2: "现金查看",
    0: "开通会员查看",
    1: "免费查看（每天3次，超出需要买会员）",
};
// 广告类型
export const advertisingType = {
    1: "文章",
    2: "活动",
    3: "报告",
    4: "跳转链接",
    6: "跳转小程序（支持小程序端和APP端）",
};

//  支付类型
export const payType = {
    0: "免费查看",
    1: "积分查看",
    2: "帆币查看",
    3: "付费查看",
    4: "会员查看",
};

export const viewType = {
    1: "在线查看",
    2: "下载",
};
